import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="m49 25v21.9c0 1.1-0.5 1.6-1.6 1.6-1 0-1.4-0.5-1.4-1.6v-21.9c0-5.9-2.3-11-6.5-15.1-2.2-2.3-5-4-7.8-5q0.9 1.8 0.9 3.9 0 1.7-1.6 1.7-1.5 0-1.5-1.7 0-2.2-1.6-3.7-1.2-1.2-2.7-1.5-0.4 0-0.9 0-0.5 0-1.1 0-1.5 0.3-2.7 1.5-1.5 1.5-1.5 3.7 0 2.3 1.5 3.8c1.1 1 3.6 1.8 5 1.9 5.1 0.4 7.7 1.4 11.1 4.9q5.2 5.1 5.2 12.5 0 7.3-5.2 12.5c-3.4 3.5-6.5 5.1-12.1 5.2 0 0-0.3 0-0.3 0q0 0 0 0-10-0.1-17.1-7.2-7.1-7.2-7.1-17.4 0-10.2 7.1-17.5 7-7 16.8-7.2 0.1 0 0.3 0 0.1 0 0.2 0c6.7 0 12.5 2.4 17.3 7.2 4.8 4.9 7.3 10.7 7.3 17.5zm-33.2-16.2q0-2.1 0.9-3.8-4 1.6-7.3 4.9-6.3 6.2-6.3 15.1 0 8.8 6.3 15.1 6.2 6.3 14.9 6.3c0.9 0 1.8-0.2 2.6-0.2 3-0.6 5.3-1.9 7.4-4.1q4.2-4.3 4.2-10.2 0-6-4.2-10.3c-2.8-2.8-4.7-3.5-8.8-3.8-2.3-0.1-5.6-1.3-7.2-2.9q-2.5-2.5-2.5-6.1z"
          transform="translate(10,15)"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
